import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  whatsappForm: FormGroup;
  highlyttoggle: boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  addLinkForm?: FormGroup;
  phoneUtil = PhoneNumberUtil.getInstance();
  isValid: boolean = false;
  formattedNumber: any;
  telInputOptions = {
    initialCountry: 'fr',
    autoHideDialCode: false,
    nationalMode: false
  };
  countryCodeSelected: string = 'fr';
  inputIsChanged: boolean = false;
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<WhatsappComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private message: MessagehandlingService, private api: ApiService) {
    this.currentRoute = window.location.pathname
    this.whatsappForm = this.fb.group({
      highlight: '',
      title: this.data.block.name,
      description: '',
      active: '',
      country_code: 'fr',
      content: '',
      dial_code: '33',
      type: '',
      slug: 'whatsapp',

    })
    // this.whatsappForm.get('country_code')?.setValue('in')
    // this.whatsappForm.get('dial_code')?.setValue('91')

    this.countryCodeSelected = this.data.block.country_code?.toLowerCase();
  }

  ngOnInit(): void {
    console.log(this.whatsappForm.value);

    // this.whatsappForm.patchValue({
    //   country_code: 'in',
    //   dial_code: '91'
    // });
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  onCountryChange(data: any) {
    this.whatsappForm.patchValue({
      country_code: data.iso2,
      dial_code: data.dialCode
    })
    this.countryCodeSelected = data.iso2;
    this.formatPhoneNumber();
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  removeCountryCode(content: string): string {
    // Remove leading country code if it starts with + and has digits
    return content.replace(/^\++\d+/, '');
  }

  submitForm() {
    let a = this.whatsappForm.value.dial_code.slice(this.whatsappForm.value.country_code.length + 1);
    let dailCode = a.replace(/\s+/g, '');
    let countryCode = this.whatsappForm.value.country_code;

    if (!countryCode.startsWith('+')) {
      countryCode = `+${countryCode}`;
    }

    console.log(countryCode);

    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      const hasEmptyFields = (
        !this.whatsappForm.value.title 
        // !this.whatsappForm.value.country_code ||
        // !dailCode
      );

      if (hasEmptyFields) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      let formData = new FormData();
      formData.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
      formData.append('row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.whatsappForm.value.title)
      formData.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
      formData.append('dial_code', '+' + this.whatsappForm.value.dial_code)
      formData.append('country_code',  this.whatsappForm.value.country_code.toUpperCase())
      formData.append('content', this.whatsappForm.value.content)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        const hasEmptyFields = (
          !this.whatsappForm.value.title ||
          // !this.whatsappForm.value.country_code ||
          !this.whatsappForm.value.dial_code
        );

        if (hasEmptyFields) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }

        let formData = new FormData
        formData.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
        formData.append('row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.whatsappForm.value.title)
        formData.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
        formData.append('country_code',  this.whatsappForm.value.country_code.toUpperCase())
        formData.append('dial_code', '+' + this.whatsappForm.value.dial_code)
        formData.append('content', this.whatsappForm.value.content)
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }


  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })
  }

  get content() {
    let showpreview: any;
    const currentContent = this.whatsappForm.value.content;
    const currentDialCode = this.whatsappForm.value.dial_code;
    const sanitizedDialCode = currentDialCode.startsWith('+') ? currentDialCode.slice(1) : currentDialCode;
    // const sanitizedData = currentContent.replace(/\s+/g, ''); 
    const sanitizedData = currentContent.replace(/\D/g, '');
    // if (sanitizedData.startsWith('+') || sanitizedData.startsWith('0')) {
    //     showpreview = sanitizedData;
    // } else {
        showpreview = `+${sanitizedDialCode} ${sanitizedData}`;
    // }

    return showpreview;
}


  previewLink() {
    let contentWithoutPlus = this.content.replace('+', '').replace(/\s+/g, '');
    window.open(this.selectedSocialInfo?.base_url + contentWithoutPlus);
  }


  telInputObject(obj: any) {
    console.log(this.whatsappForm.value.country_code);

    obj.setCountry(this.whatsappForm.value.country_code);
    // obj.setNumber('+' + this.whatsappForm.value.dial_code + this.whatsappForm.value.content);
    //this.formatPhoneNumber();
  }


  onInputChange(event: any) {
    const inputValue: string = event.target.value;
    console.log('Input value:', inputValue);

    this.whatsappForm.get('country_code')?.setValue(this.countryCodeSelected);
  }

  formatPhoneNumber() {
    let a = this.whatsappForm.value.country_code ? this.whatsappForm.value.country_code : 'fr'
    let b = this.whatsappForm.value.dial_code ? this.whatsappForm.value.dial_code : '33'
     this.whatsappForm.get('country_code')?.setValue(a)
     this.whatsappForm.get('dial_code')?.setValue(b)
    if (this.whatsappForm.value.content && this.whatsappForm.value.country_code && typeof this.whatsappForm.value.content === 'string' && typeof this.whatsappForm.value.country_code === 'string' && this.whatsappForm.value.content?.length > 5) {
      const parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.whatsappForm.value.content, this.whatsappForm.value.country_code);
      this.isValid = this.phoneUtil.isValidNumber(parsedNumber);
      this.formattedNumber = this.phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
      this.whatsappForm.get('content')?.setValue(this.formattedNumber);
    } else {
      console.error('Invalid input types');
      this.isValid = false;
    }
  }



  getCountryCode(dialCode: string) {
    try {
      const regionCode = this.phoneUtil.getRegionCodeForCountryCode(parseInt(dialCode, 10));
      const countryCode = regionCode;
      return countryCode;
    } catch (error) {
      console.error('Error getting country code:', error);
      return 'FR';
    }
  }



}
