import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { environment } from 'src/environments/environment';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-edit-whatsapp',
  templateUrl: './edit-whatsapp.component.html',
  styleUrls: ['./edit-whatsapp.component.scss']
})
export class EditWhatsappComponent {
  whatsappForm: FormGroup;
  highlyttoggle: boolean = false;
  // whatsappForm: FormGroup;
  // highlyttoggle:boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  profileData: any = []
  selectedProfileData: any = [];
  selectedProfileCount: any = 0;
  selectAllCheckbox: boolean = false;
  profileImageUrl: any;
  searchText: any
  dailCode: any;
  phoneUtil = PhoneNumberUtil.getInstance();
  isValid: boolean = false;
  formattedNumber:any;
  telInputOptions = {
    initialCountry: 'in',
    autoHideDialCode: false,
    nationalMode: false
  };
  countryCodeSelected:string = 'in';
  inputIsChanged: boolean = false;
  constructor(public dialog: MatDialog, public message: MessagehandlingService, private router: Router,
    public dialogRef: MatDialogRef<EditWhatsappComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private api: ApiService,) {
    this.currentRoute = window.location.pathname
    this.whatsappForm = this.fb.group({
      highlight: data.block.highlight == '' || data.block.highlight == '0' ? false : true,
      title: this.data.block.title,
      description: data.block.description,
      active: '',
      country_code: data.block.country_code.toLowerCase(),
      dial_code: data.block.dial_code.replace('+', ''),
      content: data.block.content,
      type: '',
      slug: ''
    })
    this.countryCodeSelected = data?.block?.country_code.toLowerCase();
  }

  ngOnInit(): void {
    console.log(this.data.block.dial_code,this.data.block.country_code.toLowerCase(),)
    this.getMemberData()
    this.profileImageUrl = environment.userImage
    this.selectedSocialInfo = this.data.block;
    this.highlyttoggle=this.data.block.highlight == '' || this.data.block.highlight == '0' ? false : true,
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }


  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element: any) => {
        if (element.member_status != 4) {
          this.profileData.push(element)
        }
      });
    })
  }

  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    if (this.selectedProfileData != '') {
      if (this.whatsappForm.value.content.charAt() == '+' || this.whatsappForm.value.content.charAt() == 0) {
        let abc = this.whatsappForm.value.content.split(this.whatsappForm.value.dial_code);
        this.dailCode = abc[1]
      } else {
        this.dailCode = this.whatsappForm.value.content
      }
      let fd = new FormData
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
      fd.append('title', this.whatsappForm.value.title)
      fd.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
      fd.append('country_code', this.whatsappForm.value.country_code.toUpperCase())
      fd.append('dial_code', '+' + this.whatsappForm.value.dial_code)
      fd.append('content', this.whatsappForm.value.content)
      fd.append('slug', this.selectedSocialInfo?.slug)
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');
        this.dialogRef.close()
      })
    }
  }


  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;
      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }

  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)

        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });
    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }
  back(): void {
    this.dialogRef.close();
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })
  }

  onCountryChange(data: any) {
      this.whatsappForm.patchValue({
         country_code: data.iso2,
        dial_code: data.dialCode
      });
      this.countryCodeSelected = data.iso2;
    this.formatPhoneNumber();
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

 
  get content() {
    debugger
    let showpreview: any;
    const currentContent = this.whatsappForm.value.content;
    const currentDialCode = this.whatsappForm.value.dial_code;
    const sanitizedDialCode = currentDialCode.startsWith('+') ? currentDialCode.slice(1) : currentDialCode;
    // const sanitizedData = currentContent.replace(/\s+/g, ''); 
    const sanitizedData = currentContent.replace(/\D/g, '');
    // if (sanitizedData.startsWith('+') || sanitizedData.startsWith('0')) {
    //     showpreview = sanitizedData;
    // } else {
        showpreview = `+${sanitizedDialCode} ${sanitizedData}`;
    // }

    return showpreview;
}

// get content() {
//   return this.whatsappForm.get('content')!.value
// }


  previewLink() {
    let contentWithoutPlus = this.content.replace('+', '').replace(/\s+/g, '');
    window.open(this.selectedSocialInfo?.base_url + contentWithoutPlus);
  }
  // submitForm() {
  //   if (this.whatsappForm.value.highlight && !this.whatsappForm.value.description) {
  //     this.message.errorMessage('Please provide a description.', 2000);
  //     return;
  //   }

  //   if (this.whatsappForm.value.content.charAt() == '+' || this.whatsappForm.value.content.charAt() == 0) {
  //     let abc = this.whatsappForm.value.content.split(this.whatsappForm.value.dial_code);
  //     this.dailCode = abc[1]
  //   } else {
  //     this.dailCode = this.whatsappForm.value.content
  //   }
  //   if (
  //     // !this.whatsappForm.get('content')?.value ||
  //     // !this.whatsappForm.get('title')?.value ||
  //     // !this.whatsappForm.get('country_code')?.value ||
  //     this.whatsappForm.get('dial_code')?.value
  //   ) {
  //     this.message.errorMessage('Please fill in all required fields.', 2000);
  //     return;
  //   } else {
  //     let formData = new FormData();
  //     formData.append('id', this.selectedSocialInfo.id)
  //     formData.append('highlight', this.whatsappForm.value.highlight == '' || this.whatsappForm.value.highlight == false ? '0' : '1')
  //     formData.append('title', this.whatsappForm.value.title)
  //     formData.append('description', this.whatsappForm.value.highlight == true ? this.whatsappForm.value.description : '')
  //     formData.append('country_code', this.whatsappForm.value.country_code.toUpperCase())
  //     formData.append('dial_code', '+' + this.whatsappForm.value.dial_code)
  //     formData.append('content', this.dailCode)
  //     formData.append('slug', this.selectedSocialInfo?.slug)
  //     formData.append('active', '')
  //     formData.append('type', 'primary')
  //     formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //     formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
  //     this.api.updateLink(formData).subscribe((res: any) => {
  //       this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully updated to card`, 2000)
  //       this.dialog.closeAll();
  //     })

  //     const currentRoute = this.router.url;
  //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //       this.router.navigate([currentRoute]); // navigate to same route
  //     });
  //   }
  // }

  submitForm() {
    if (this.whatsappForm.value.highlight && !this.whatsappForm.value.description) {
        this.message.errorMessage('Please provide a description.', 2000);
        return;
    }
    if (this.whatsappForm.value.content.startsWith('+') || this.whatsappForm.value.content.startsWith('0')) {
        let abc = this.whatsappForm.value.content.split(this.whatsappForm.value.dial_code);
        this.dailCode = abc[1];
    } else {
        this.dailCode = this.whatsappForm.value.content;
    }
    if (!this.whatsappForm.get('content')?.value 
        // !this.whatsappForm.get('title')?.value || 
        // !this.whatsappForm.get('country_code')?.value || 
        // !this.whatsappForm.get('dial_code')?.value
        ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
    }
    let formData = new FormData();
    formData.append('id', this.selectedSocialInfo.id);
    formData.append('highlight', this.whatsappForm.value.highlight ? '1' : '0');
    formData.append('title', this.whatsappForm.value.title);
    formData.append('description', this.whatsappForm.value.highlight ? this.whatsappForm.value.description : '');
    formData.append('country_code', this.whatsappForm.value.country_code.toUpperCase());
    formData.append('dial_code', '+' + this.whatsappForm.value.dial_code);
    formData.append('content', this.whatsappForm.value.content);
    formData.append('slug', this.selectedSocialInfo?.slug);
    formData.append('active', '');
    formData.append('type', 'primary');
    formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '');
    formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '');

   
    this.api.updateLink(formData).subscribe((res: any) => {
        this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully updated to card`, 2000);
        this.dialog.closeAll();
    });
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]);
    });
}


  telInputObject(obj: any) {
    obj.setCountry(this.whatsappForm.value.country_code);
      obj.setNumber('+' + this.whatsappForm.value.dial_code + this.whatsappForm.value.content);
    //this.formatPhoneNumber();
    

  }


  onInputChange(event: any) {
    const inputValue: string = event.target.value;
    console.log('Input value:', inputValue);

    this.whatsappForm.get('country_code')?.setValue(this.countryCodeSelected);
  }

  formatPhoneNumber() {
    if (this.whatsappForm.value.content && this.whatsappForm.value.country_code && typeof this.whatsappForm.value.content === 'string' && typeof this.whatsappForm.value.country_code === 'string' && this.whatsappForm.value.content?.length>5) {
        const parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.whatsappForm.value.content, this.whatsappForm.value.country_code);
        this.isValid = this.phoneUtil.isValidNumber(parsedNumber);
        this.formattedNumber = this.phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
        this.whatsappForm.get('content')?.setValue(this.formattedNumber);
      
      

        } else {
          console.error('Invalid input types');
          this.isValid = false;
        }
  }

 

  getCountryCode(dialCode: string) {
    try {
      const regionCode = this.phoneUtil.getRegionCodeForCountryCode(parseInt(dialCode, 10));
      const countryCode = regionCode;
      return countryCode;
    } catch (error) {
      console.error('Error getting country code:', error);
      return 'IN';
    }
  }
 
}
