import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

  phoneForm: FormGroup;
  highlyttoggle: boolean = false;
  currentRoute: any;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  phoneUtil = PhoneNumberUtil.getInstance();
  isValid: boolean = false;
  formattedNumber: any;
  telInputOptions = {
    initialCountry: 'fr',
    autoHideDialCode: false,
    nationalMode: false
  };
  countryCodeSelected: string = 'fr';
  inputIsChanged: boolean = false;
  constructor(public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<PhoneComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private message: MessagehandlingService, private api: ApiService) {
    this.currentRoute = window.location.pathname
    this.phoneForm = this.fb.group({
      highlight: '',
      title: this.data.block.name,
      description: '',
      active: '',
      country_code: 'fr',
      content: '',
      dial_code: '33',
      type:'',
      slug:'',

    })
    // this.phoneForm.get('country_code')?.setValue('fr')
    // this.phoneForm.get('dial_code')?.setValue('33')

    this.countryCodeSelected = this.data.block.country_code?.toLowerCase();

  }
  ngOnInit(): void {
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  onCountryChange(data: any) {
    debugger
    this.phoneForm.patchValue({
      country_code: data.iso2,
      dial_code: data.dialCode
    })
    this.countryCodeSelected = data.iso2;
    this.formatPhoneNumber();
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }
  submitForm() {
    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      if (
        // !this.phoneForm.get('content')?.value ||
        !this.phoneForm.get('title')?.value ||
        // !this.phoneForm.get('country_code')?.value ||
        !this.phoneForm.get('dial_code')?.value
      ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      let formData = new FormData();
      formData.append('highlight', this.phoneForm.value.highlight == '' || this.phoneForm.value.highlight == false ? '0' : '1')
      formData.append('row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.phoneForm.value.title)
      formData.append('description', this.phoneForm.value.highlight == true ? this.phoneForm.value.description : '')
      formData.append('country_code',  this.phoneForm.value.country_code.toUpperCase())
      formData.append('dial_code','+' + this.phoneForm.value.dial_code)
      formData.append('content', this.phoneForm.value.content)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
     
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        if (
          // !this.phoneForm.get('content')?.value ||
          !this.phoneForm.get('title')?.value ||
          // !this.phoneForm.get('country_code')?.value ||
          !this.phoneForm.get('dial_code')?.value
        ) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('highlight', this.phoneForm.value.highlight == '' || this.phoneForm.value.highlight == false ? '0' : '1')
        formData.append('row_order', this.profileIconArrayLength + 1)
        formData.append('title', this.phoneForm.value.title)
        formData.append('description', this.phoneForm.value.highlight == true ? this.phoneForm.value.description : '')
        formData.append('country_code',  this.phoneForm.value.country_code.toUpperCase())
        formData.append('dial_code', '+' + this.phoneForm.value.dial_code)
        formData.append('slug', this.selectedSocialInfo?.slug)
        formData.append('active', '')
        formData.append('content', this.phoneForm.value.content)
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }


  updateLink(formData: any) {
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })
  }

  get content() {
    console.log(this.phoneForm.get('dial_code')!.value+''+this.phoneForm.get('content')!.value);
    
    return '+'+this.phoneForm.get('dial_code')!.value+''+this.phoneForm.get('content')!.value
  }

  // get content() {
  //   return this.phoneForm.get('content')!.value
  // }
 
previewLink() {
  debugger
  let contentWithoutPlus = this.content.replace('+', '').replace(/\s+/g, '');
  window.open(this.selectedSocialInfo?.base_url + contentWithoutPlus);
}

telInputObject(obj: any) {
  console.log(this.phoneForm.value.country_code);

  obj.setCountry(this.phoneForm.value.country_code);
  // obj.setNumber('+' + this.phoneForm.value.dial_code + this.phoneForm.value.content);
  //this.formatPhoneNumber();
}


onInputChange(event: any) {
  const inputValue: string = event.target.value;
  console.log('Input value:', inputValue);

  this.phoneForm.get('country_code')?.setValue(this.countryCodeSelected);
}

formatPhoneNumber() {
  let a = this.phoneForm.value.country_code ? this.phoneForm.value.country_code : 'fr'
  let b = this.phoneForm.value.dial_code ? this.phoneForm.value.dial_code : '33'
  this.phoneForm.get('country_code')?.setValue(a)
  this.phoneForm.get('dial_code')?.setValue(b)
  if (this.phoneForm.value.content && this.phoneForm.value.country_code ? this.phoneForm.value.country_code : 'fr' && typeof this.phoneForm.value.content === 'string' && typeof this.phoneForm.value.country_code === 'string' && this.phoneForm.value.content?.length > 5) {
    const parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.phoneForm.value.content, this.phoneForm.value.country_code);
    this.isValid = this.phoneUtil.isValidNumber(parsedNumber);
    this.formattedNumber = this.phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
    this.phoneForm.get('content')?.setValue(this.formattedNumber);
  } else {
    console.error('Invalid input types');
    this.isValid = false;
  }
}



getCountryCode(dialCode: string) {
  try {
    const regionCode = this.phoneUtil.getRegionCodeForCountryCode(parseInt(dialCode, 10));
    const countryCode = regionCode;
    return countryCode;
  } catch (error) {
    console.error('Error getting country code:', error);
    return 'FR';
  }
}

}

